import { useCookies } from 'react-cookie'
import LoginLink from '../../modules/i18n/LoginLink'
import urls from '../../modules/urls'
import { COOKIE_LIST, COOKIE_OPT, SIGN_UP_MODE } from '../../modules/vars'

type LoginLinkPropsType = {
  href: string
  children?: any
  mode?: any
  afterLogin?: any
  className?: any
  tutor?: any
  as?: any
  [x: string]: any
}

const LoginLinkA = ({
  href,
  children,
  mode,
  afterLogin,
  className,
  tutor = false,
  as,
  ...others
}: LoginLinkPropsType) => {
  const [cookies, setCookies] = useCookies([COOKIE_LIST.LOGIN_AFTER_URL])

  const setLoginAfterUrl = () => {
    if (mode == SIGN_UP_MODE.CORRECTION) {
      setCookies(COOKIE_LIST.LOGIN_AFTER_URL, urls.student.portal.correction.request, COOKIE_OPT)
    }
    if (afterLogin) {
      setCookies(COOKIE_LIST.LOGIN_AFTER_URL, afterLogin, COOKIE_OPT)
    }
  }

  return (
    <LoginLink tutor={tutor} href={href} as={as} mode={mode} {...others} afterLogin={afterLogin}>
      <a onClick={setLoginAfterUrl} className={className} {...others}>
        {children}
      </a>
    </LoginLink>
  )
}

export default LoginLinkA
